<template>
  <section>
    <div class="container-lg">
      <!-- image -->
      <div class="row">
        <div
          class="col stats-image rds-lazy"
          :data-background-image="bgImage"
        ></div>
        <div class="w-100 stats-image-gradient"></div>
      </div>
      <!-- title row -->
      <div class="row">
        <div class="col-12 col-md-10">
          <div class="px-space-xs px-md-space-md">
            <h2
              class="position-relative h2-medium title-margin pt-space-md pt-md-space-lg pt-lg-0 pb-space-sm pb-md-space-md pb-lg-space-sm mb-0"
            >
              {{ title }}
            </h2>
          </div>
        </div>
      </div>

      <!-- description row -->
      <div v-if="description" class="row">
        <div class="col-12">
          <div
            v-html="description"
            class="px-space-xs px-md-space-md mb-space-md mb-lg-space-lg"
          ></div>
        </div>
      </div>

      <!-- program accolades -->
      <div class="row pb-lg-space-xxs" v-if="currentAccolades.length > 0">
        <div
          class="col-12 col-lg-6"
          v-for="(stat, index) in currentAccolades"
          :key="index"
        >
          <div
            class="px-space-xs px-md-space-md pb-space-sm pb-md-space-md pb-lg-space-sm"
          >
            <h3
              class="h2-medium pb-space-xxs mb-0 fw-bold"
              v-html="stat.title"
            ></h3>

            <hr
              class="title-underline mb-space-xxs mt-0 d-inline-block border-0"
              :class="hrClass"
            />

            <div v-html="stat.description"></div>
          </div>
        </div>
      </div>

      <!-- show more/less links -->
      <div v-if="displayShowAllLess" class="row">
        <div class="col-12">
          <div class="text-center pb-space-lg">
            <button
              @click="handleShowAllLessClick"
              class="btn btn-link text-decoration-underline text-primary show-text"
            >
              {{ getShowAllLessText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import bgImage from "~/assets/img/program_accolades_image.jpg?url";
import { VariantOption } from "~/types/types";

interface AccoladeItem {
  title: string;
  description: string;
}

interface Props {
  underlineVariant?: VariantOption;
  title: string;
  description?: string | null;
  items: AccoladeItem[];
  initialItemsNumber?: number;
}

const props = withDefaults(defineProps<Props>(), {
  underlineVariant: "secondary",
  initialItemsNumber: 4,
});

const allAccoladesShown = ref(false);

const currentAccolades = computed(() => {
  if (allAccoladesShown.value) {
    return props.items;
  } else if (props.items && props.items.length > 0) {
    return props.items.slice(0, props.initialItemsNumber);
  } else {
    console.log("no items");
    return [];
  }
});

const hrClass = computed(() => {
  return `bg-${props.underlineVariant}`;
});
const displayShowAllLess = computed(() => {
  return props.items?.length > props.initialItemsNumber;
});
const getShowAllLessText = computed(() => {
  return allAccoladesShown.value ? "Show less" : "Show more";
});

const handleShowAllLessClick = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    props.title.toLowerCase(),
    getShowAllLessText.value.toLowerCase(),
    "accolades"
  );
  allAccoladesShown.value = !allAccoladesShown.value;
};
</script>

<style scoped>
.stats-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 245px;
  padding-left: 0;
  padding-right: 0;
}
.stats-image-gradient {
  left: 0px;
  height: 245px;
  position: absolute;
  background: transparent
    linear-gradient(180deg, #ffffff00 0%, #ffffffa0 80%, #ffffff 100%) 0% 0%
    no-repeat;
}
.title-underline {
  height: 3px;
  width: 12%;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .stats-image {
    height: 400px;
  }
  .stats-image-gradient {
    height: 400px;
  }
  .title-underline {
    width: 6%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .stats-image {
    height: 550px;
  }
  .stats-image-gradient {
    height: 550px;
  }
  .title-underline {
    width: 8%;
  }
  .title-margin {
    margin-top: -40px;
  }
}
</style>
