<template>
  <div
    v-if="showAlert"
    class="mb-space-xs alert w-100"
    :class="[getColorClass]"
    role="alert"
    aria-live="polite"
    aria-atomic="true"
  >
    <slot />

    <button
      v-if="dismissible"
      class="position-absolute close"
      @click="handleClose"
    >
      <font-awesome-icon
        :icon="['fas', 'xmark']"
        class="x-icon mb-0 position-relative"
      ></font-awesome-icon>
    </button>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { VariantOption } from "~/types/types";

const props = withDefaults(
  defineProps<{
    variant: VariantOption;
    dismissible?: boolean;
    show?: boolean;
  }>(),
  {
    variant: "primary",
    dismissible: false,
    show: false,
  }
);

const showAlert = ref<boolean>(props.show);
const emit = defineEmits(["alert-close"]);

const handleClose = (e: Event) => {
  showAlert.value = false;
  emit("alert-close", e);
};
const getColorClass = computed<string>(() => {
  return `bg-${props.variant}`;
});
</script>

<style scoped lang="scss">
button {
  border: none;
  background-color: transparent;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
  opacity: 0.5;
  transition: opacity 200 linear;

  &:hover {
    opacity: 1;
  }
}
.x-icon {
  font-size: 1.5rem;
  line-height: 1;
}
</style>
