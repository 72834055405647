<template>
  <component
    :is="componentType"
    class="pt-space-md pt-md-space-lg pt-lg-space-xxl pb-space-sm pb-md-space-xxs pb-lg-space-xxl collapse-section"
    :class="sectionClass"
    ref="sectionRef"
  >
    <div class="container">
      <!-- header row -->
      <div class="row" v-if="title">
        <div class="col">
          <h2 class="h2-medium mb-space-md mb-lg-space-lg px-space-xs px-md-0">
            {{ title }}
          </h2>
        </div>
      </div>

      <!-- descrtiption row -->
      <div v-if="description" class="row">
        <div class="col">
          <div
            class="mb-space-md mb-lg-space-lg px-space-xs px-md-0"
            v-html="description"
          ></div>
        </div>
      </div>

      <!-- collapse items row -->
      <div class="row">
        <div class="col collapse-items">
          <div
            v-for="(item, index) in currentCollapseItems"
            class="mb-space-xs"
          >
            <collapse-item
              class="bg-white"
              :collapse-id="generateCollapseId(index)"
              border-variant="light-2"
              :highlight-width="8"
              :display-number="false"
              :title="item.title"
              @collapseShown="handleCollapseEvent('open', title!, item?.title!)"
              @collapseHidden="
                handleCollapseEvent('close', title!, item?.title!)
              "
              ><div v-html="item.description"></div
            ></collapse-item>
          </div>
        </div>
      </div>

      <!-- show more/less links -->
      <div v-if="displayShowAllLess" class="row">
        <div class="col">
          <div class="text-center">
            <a
              @click="handleShowAllLessClick"
              class="fs-large text-primary show-text"
            >
              {{ getShowAllLessText }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { CollapseItem } from "@rds-vue-ui/collapse-item";
import { VariantOption } from "~/types/types";

export interface CollapseItem {
  title?: string;
  description: string;
}

interface Props {
  componentType?: "section" | "div";
  bgColor?: VariantOption;
  title?: string;
  description?: string | null;
  collapseItems: CollapseItem[];
  initialItemsNumber?: number;
  eventComponent: string;
}

const props = withDefaults(defineProps<Props>(), {
  componentType: "section",
  bgColor: "light-2",
  initialItemsNumber: 5,
});

const showAllItems = ref(false);
const sectionRef = ref<HTMLElement | null>(null);

const currentCollapseItems = computed(() => {
  return showAllItems.value
    ? props.collapseItems.slice(0, props.initialItemsNumber)
    : props.collapseItems;
});

const getShowAllLessText = computed(() => {
  return showAllItems.value ? "Show more" : "Show less";
});

const sectionClass = computed(() => {
  return `bg-${props.bgColor}`;
});

const displayShowAllLess = computed(() => {
  return props.collapseItems.length > props.initialItemsNumber;
});

const handleShowAllLessClick = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    props?.title?.toLowerCase()!,
    getShowAllLessText.value.toLowerCase(),
    props.eventComponent.toLowerCase()
  );
  showAllItems.value = !showAllItems.value;

  if (sectionRef.value && showAllItems.value) {
    sectionRef.value.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

const generateCollapseId = (index: number) => {
  return `${props.eventComponent.toLowerCase().replace(/ /g, "-")}-${index}`;
};

const handleCollapseEvent = (action: string, section: string, text: string) => {
  analyticsComposable.trackCollapseEvent(
    "onclick",
    action.toLowerCase(),
    "click",
    "main content",
    section.toLowerCase(),
    text.toLowerCase(),
    props.eventComponent.toLowerCase()
  );
};
</script>
