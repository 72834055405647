<template>
  <section
    class="bg-white py-space-md py-lg-space-xxl px-space-md px-lg-space-xxxl"
  >
    <div class="container">
      <div class="row">
        <div class="col col-lg-10 offset-lg-1">
          <!-- title -->
          <h2 class="h2-medium mb-space-sm" id="degree-detail">{{ title }}</h2>

          <!-- description -->
          <div v-html="getDescription"></div>

          <!-- read more link -->
          <div class="text-center" v-if="showReadMore">
            <button
              class="btn btn-link text-decoration-underline show-text text-primary fs-medium p-0"
              @click="toggleReadMore"
            >
              Read more
            </button>
          </div>

          <!-- additional content -->
          <div v-show="showMoreText">
            <div v-html="getMoreDescription"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";

interface Props {
  title: string;
  description: string;
}

const props = defineProps<Props>();

const showMoreText = ref(false);

const getDescription = computed(() => {
  let desc = props.description
    ? props.description.replace(/(\r\n|\r\n\t|\n|\r|\t)/gm, "").split("</p>", 4)
    : [""];
  const sanitizedDesc = desc.filter((item) => {
    return item !== "";
  });
  return sanitizedDesc.length > 0 ? sanitizedDesc.join("").toString() : "";
});

const showReadMore = computed(() => {
  return (
    sanitizedDesc.value.slice(0, sanitizedDesc.value.length - 1).length >= 4 &&
    !showMoreText.value
  );
});

const getMoreDescription = computed(() => {
  return sanitizedDesc.value.slice(4).join("").toString();
});

const sanitizedDesc = computed(() => {
  const desc = props.description
    ? props.description.replace(/(\r\n|\r\n\t|\n|\r|\t)/gm, "").split("</p>")
    : [""];
  return desc.filter((item) => {
    return item !== "";
  });
});

const toggleReadMore = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    props.title.toLowerCase(),
    "read more",
    "details"
  );
  showMoreText.value = !showMoreText.value;
};
</script>
