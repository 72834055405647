<template>
  <section
    :id="id"
    class="bg-light-2 pb-space-md pb-md-space-lg pt-lg-space-lg pb-lg-space-xl"
  >
    <div class="container-lg px-0 px-lg-gutter">
      <div class="row gx-0 border border-grey bg-white">
        <!-- image column -->
        <div class="col-lg-5 rfi-image"></div>
        <!-- rfi column -->
        <div class="col-12 col-lg-7 px-0 bg-white">
          <form
            :id="`${id}_asuo-rfi`"
            @submit.prevent="handleSubmit"
            data-cy="rfi-form"
          >
            <div
              class="py-space-md px-space-xs py-md-space-lg px-md-space-sm py-lg-space-xl px-lg-space-xxl"
            >
              <h2 class="h2-medium mb-space-sm" v-if="title">{{ title }}</h2>

              <p class="mb-space-sm fs-medium">
                Have questions about the
                <strong>{{ programName }}</strong
                >? Fill out this form and we’ll get in touch!
              </p>

              <p class="mb-space-sm fs-small">* Indicates a required field</p>

              <div :id="`${id}_form-fields`" class="mb-lg-space-md">
                <!-- first-name input field -->
                <div
                  :id="`${id}_first-name-group`"
                  class="position-relative mb-0 pb-space-sm d-flex flex-column"
                >
                  <label for="first-name" class="fw-bold mb-space-xxs"
                    >* First name</label
                  >
                  <input
                    v-model.trim="form.firstName"
                    type="text"
                    :id="`${id}_first-name`"
                    placeholder="Enter your first name"
                    data-cy="rfi-first-name"
                    class="form-control rounded-0"
                    :class="{
                      'is-invalid': firstNameErrorMessage,
                    }"
                    @blur="handleFirstNameBlur()"
                  />
                  <div
                    v-if="firstNameErrorMessage"
                    class="invalid-feedback mb-space-xxs"
                  >
                    {{ firstNameErrorMessage }}
                  </div>
                </div>
                <!-- last-name input field -->
                <div
                  :id="`${id}_last-name-group`"
                  class="position-relative mb-0 pb-space-sm"
                >
                  <label for="last-name" class="fw-bold mb-space-xxs"
                    >* Last name</label
                  >
                  <input
                    v-model.trim="form.lastName"
                    type="text"
                    :id="`${id}_last-name`"
                    placeholder="Enter your last name"
                    data-cy="rfi-last-name"
                    class="form-control rounded-0"
                    :class="{
                      'is-invalid': lastNameErrorMessage,
                    }"
                    @blur="handleLastNameBlur()"
                  />
                  <div
                    v-if="lastNameErrorMessage"
                    class="invalid-feedback mb-space-xxs"
                  >
                    {{ lastNameErrorMessage }}
                  </div>
                </div>
                <!-- email input field -->
                <div
                  :id="`${id}_email-group`"
                  class="position-relative mb-0 pb-space-sm"
                >
                  <label for="email" class="fw-bold mb-space-xxs"
                    >* Email</label
                  >
                  <input
                    :id="`${id}_email`"
                    type="email"
                    placeholder="Enter your email address"
                    v-model.trim="form.email"
                    @input="handleEmailInput()"
                    @blur="handleEmailBlur()"
                    class="form-control rounded-0"
                    :class="{
                      'is-invalid': emailErrorMessage,
                    }"
                    data-cy="rfi-email"
                  />
                  <div
                    v-if="emailErrorMessage"
                    class="invalid-feedback mb-space-xxs"
                  >
                    {{ emailErrorMessage }}
                  </div>
                </div>
                <!-- phone number input field -->
                <div
                  :id="`${id}_phone-number-group`"
                  class="position-relative mb-0 pb-space-md"
                >
                  <label
                    for="asuonline_phone_number"
                    class="fw-bold mb-space-xxs"
                    >* Phone number</label
                  >
                  <ClientOnly>
                    <PhoneInputRfi
                      :id="`${id}_asuonline_phone_number`"
                      class="w-100"
                      :class="{ 'is-invalid': phoneErrorMessage }"
                      :error="phoneErrorMessage != ''"
                      v-model.trim="form.phone.number"
                      :no-validator-state="true"
                      @update="handlePhoneUpdate"
                      @blur="handlePhoneBlur"
                    ></PhoneInputRfi>
                  </ClientOnly>
                  <div
                    v-if="phoneErrorMessage"
                    class="invalid-feedback mb-space-xxs"
                  >
                    {{ phoneErrorMessage }}
                  </div>
                </div>
                <!-- military checkbox -->
                <div
                  id="military-group"
                  class="form-check ps-0 position-relative mb-0 pb-space-sm d-flex flex-wrap"
                >
                  <label
                    class="fw-bold form-check-label fs-medium w-100"
                    for="military"
                  >
                    * Have you served in the U.S. military or are you a military
                    dependent?
                  </label>
                  <div class="form-check" v-for="option in militaryOptions">
                    <label class="form-check-label">
                      {{ option.label }}
                      <input
                        class="form-check-input"
                        type="radio"
                        :value="option.value"
                        v-model="form.isMilitary"
                        @click="handleMilitaryClick()"
                      />
                    </label>
                  </div>
                </div>
                <!-- consent block -->
                <div class="fs-xs mb-space-sm mb-md-space-md gdpr-links">
                  <text-gdpr text-class="xs"></text-gdpr>
                </div>
              </div>

              <!-- submit block -->
              <div class="row gx-0">
                <div class="col-12">
                  <button
                    class="btn fs-xl fw-bold px-space-sm py-space-xs btn-secondary w-100 d-block"
                    type="submit"
                    :disabled="submitDisabled"
                    variant="secondary"
                    data-cy="rfi-submit-button"
                  >
                    Submit
                    <div class="d-inline" v-if="showLoader">
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Loading...</span>
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Loading...</span>
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import defaultBgImage from "~/assets/img/rfi-bg-overlay.png?url";
import TextGdpr from "~/components/rfi/TextGdpr.vue";
import PhoneInputRfi from "./PhoneInputRfi.vue";

interface Props {
  title?: string;
  bgImage?: string;
  career: string;
  id: string;
  // content id for the program
  programId: string;
  programName: string;
}
const props = withDefaults(defineProps<Props>(), {
  title: "Degree questions, answered",
  bgImage: defaultBgImage,
});

const {
  form,
  lastNameErrorMessage,
  emailErrorMessage,
  phoneErrorMessage,
  firstNameErrorMessage,
  showLoader,
  handleEmailBlur,
  handleEmailInput,
  handleFirstNameBlur,
  handleLastNameBlur,
  handleMilitaryClick,
  handlePhoneBlur,
  handlePhoneUpdate,
  handleSubmit,
  submitDisabled,
} = useRfi({
  isDegreePage: true,
  programId: props.programId,
  programName: props.programName,
});

const militaryOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
</script>

<style lang="scss" scoped>
.form-check-input:checked {
  color: var(--rds-dark-3);
  --rds-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  --rds-form-check-bg: var(--rds-secondary);
  background-color: var(--rds-secondary);
  border-color: var(--rds-secondary);
}
.form-check {
  gap: 1rem;
}
.gdpr-links {
  :deep(a) {
    text-decoration: underline;
  }
}
.ms-rfi-section {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.rfi-image {
  background-image: url("~/assets/img/rfi-degree-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  min-height: 200px;
}

div.invalid-feedback {
  margin-top: 0 !important;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .rfi-image {
    min-height: 400px;
    background-image: url("~/assets/img/rfi-degree-tablet.png");
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .rfi-image {
    min-height: 884px;
    background-image: url("~/assets/img/rfi-card-image.jpg");
  }
}
</style>
