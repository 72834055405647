<template>
  <div class="h-100">
    <card-text-atlas
      :title="title"
      :is-clickable="true"
      :display-footer="true"
      :display-label="showLabel"
      :label-text="label"
      cta-link="javascript:void(0)"
      cta-text="View course details"
      @cardClick="showModal"
      @cardEnter="showModal"
      @ctaClick.prevent
      class="h-100"
    ></card-text-atlas>
    <rds-modal
      @hidden="descriptionModal = false"
      @close="hideModal"
      :visible="descriptionModal"
      ref="descriptiveModal"
      use-custom-close
    >
      <div class="d-block">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            aria-label="Close"
            class="modal-close me-space-xs mt-space-xxs"
            @click="hideModal"
          >
            ×
          </button>
        </div>
        <div class="p-space-md">
          <h3 class="h3-large mb-space-sm">
            {{ title }}
          </h3>
          <div class="fs-sm mb-0" v-html="description"></div>
        </div>
      </div>
    </rds-modal>
  </div>
</template>

<script setup lang="ts">
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { CardTextAtlas } from "@rds-vue-ui/card-text-atlas";
import { ref } from "vue";

interface Props {
  title: string;
  description: string;
  showLabel?: boolean;
  label?: string;
  eventComponent: string;
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: false,
});

const descriptionModal = ref(false);

const showModal = () => {
  descriptionModal.value = true;
  const { title, eventComponent } = props;
  analyticsComposable.trackModalEvent(
    "onclick",
    "open",
    "click",
    "main content",
    title,
    "view details",
    eventComponent
  );
};
const hideModal = () => {
  descriptionModal.value = false;
  const { title, eventComponent } = props;
  analyticsComposable.trackModalEvent(
    "onclick",
    "open",
    "click",
    "close cross",
    title,
    "view details",
    eventComponent
  );
};
</script>

<style lang="scss" scoped></style>
