<template>
  <div>
    <section-parallax-apollo
      class="pb-space-lg py-lg-0"
      :title="title"
      title-size="small"
      bg-image-source="https://live-asuocms.ws.asu.edu/sites/default/files/cost-image.jpg"
      :display-cta="true"
      @ctaClick="showProgramCostModal"
      cta-text="Estimate tuition and fees"
      cta-text-size="small"
      :cta-as-button="true"
      cta-button-variant="secondary"
      cta-text-variant="dark-3"
      cta-text-weight="bold"
      backdrop-height="309"
      backgroundImage="https://live-asuocms.ws.asu.edu/sites/default/files/cost-image.jpg"
    >
      <div v-html="text" class="mb-space-sm mb-lg-space-md"></div>
    </section-parallax-apollo>
    <rds-modal
      @hidden="showModal = false"
      @close="handleModalClose"
      :visible="showModal"
      bg-variant="light-3"
      :expand-height="true"
      :remove-padding="true"
    >
      <div>
        <ClientOnly>
          <program-cost
            ref="programCostRef"
            :programCategory="programCategory"
            :program-code="programCode"
            :isMountedModal="showModal"
            :costOverride="costOverride"
            :costAcademicYear="costAcademicYear"
            :fee-code-resident="feeCodeResident"
            :fee-code-non-resident="feeCodeNonResident"
            :costCollection="costCollection"
            :degreeType="degreeType"
          ></program-cost>
        </ClientOnly>
      </div>
    </rds-modal>
  </div>
</template>

<script setup lang="ts">
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { RdsModal } from "@rds-vue-ui/rds-modal";
import { SectionParallaxApollo } from "@rds-vue-ui/section-parallax-apollo";
import ProgramCost from "~/components/degree-page/ProgramCost.vue";
/*
 ********************************************
 * Props
 ********************************************
 */

type Props = {
  title: string;
  programCategory: string;
  costOverride: boolean;
  costAcademicYear: string;
  programCode: string;
  feeCodeResident: string;
  feeCodeNonResident: string;
  degreeType: "Graduate" | "Undergraduate" | "Certificate";
  costCollection: {
    amount: number;
    summary: string | null;
  } | null;
  text?: string;
};
defineProps<Props>();
/*
 ********************************************
 * data
 ********************************************
 */
const showModal = ref(false);
const programCostRef = ref<InstanceType<typeof ProgramCost>>();
/*
 ********************************************
 * Methods
 ********************************************
 */
const showProgramCostModal = async (e: Event) => {
  e.preventDefault();
  showModal.value = true;
  // fires fetchAll function to make API calls from tuition API on modal open button click.
  await programCostRef.value?.fetchCostApi();
  analyticsComposable.trackModalEvent(
    "onclick",
    "open",
    "click",
    "main content",
    "tuition calculator",
    "estimated tuition and fees"
  );
};
const handleModalClose = () => {
  analyticsComposable.trackModalEvent(
    "onclick",
    "close",
    "click",
    "main content",
    "your tuition estimate",
    "close cross"
  );
};
</script>

<style scoped></style>
