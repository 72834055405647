import { useFetch, useRuntimeConfig } from "#imports";
import { AsuTuitionApiResponse } from "@/types/types";
import { type RuntimeConfig } from "nuxt/schema";

type ResidentStatusType = "res" | "nores";
type AcademicCareerType = "UGRD" | "GRAD";

export class UseTuitionApi {
  academicYears: number[];
  careerType: AcademicCareerType;
  programCode: string;
  config: RuntimeConfig;
  resFeeCode: string;
  nonResFeeCode: string;

  constructor(
    currentYear: string,
    careerType: AcademicCareerType,
    programCode: string,
    resFeeCode: string,
    nonResFeeCode: string
  ) {
    const yearToInt = parseInt(currentYear);
    this.academicYears = [];
    for (let i = 0; i < 2; i++) {
      this.academicYears.push(yearToInt - i);
    }
    this.careerType = careerType;
    this.programCode = programCode;
    this.resFeeCode = resFeeCode;
    this.nonResFeeCode = nonResFeeCode;
    this.config = useRuntimeConfig();
  }
  private setCostUrl = (year: number, res: ResidentStatusType) => {
    return `${this.config.public.asuoApiBaseUrl}${this.config.public.asuoApiCostInfoPath}?honors=0&admit_term=&include_summer=0&campus=ONLNE&acad_year=${year}&acad_prog=${this.programCode}&residency=${res}&acad_career=${this.careerType}`;
  };
  private setUrlList = (): {
    resUrlArray: string[];
    noresUrlArray: string[];
  } => {
    const resUrlArray: string[] = [];
    const noresUrlArray: string[] = [];
    for (let idx = 0; idx < this.academicYears.length; idx++) {
      let resUrl = this.setCostUrl(this.academicYears[idx], "res");
      if (this.resFeeCode !== "") {
        resUrl = resUrl + `&program_fee=${this.resFeeCode}`;
      }
      let noresUrl = this.setCostUrl(this.academicYears[idx], "nores");
      if (this.nonResFeeCode !== "") {
        noresUrl = noresUrl + `&program_fee=${this.nonResFeeCode}`;
      }
      resUrlArray.push(resUrl);
      noresUrlArray.push(noresUrl);
    }
    return {
      resUrlArray,
      noresUrlArray,
    };
  };
  public getAcademicYearText = () => {
    const academicYearList: string[] = [];
    for (let i = 0; i < this.academicYears.length; i++) {
      academicYearList.push(
        `${this.academicYears[i] - 1}-${this.academicYears[i]}`
      );
    }
    return academicYearList;
  };
  public fetchResCostInfo = async () => {
    const urlArray = this.setUrlList();
    // make API calls for resident tuition information on parallel (lazyloaded)
    const res = await Promise.all(
      urlArray.resUrlArray.map((item, idx) =>
        useFetch<AsuTuitionApiResponse>(item, {
          server: false,
          lazy: true,
          key: `${this.programCode}-${this.careerType}-res-${idx}`,
        })
      )
    );
    return res;
  };
  public fetchNoresCostInfo = async () => {
    const urlArray = this.setUrlList();
    // make API calls for non-resident tuition information on parallel (lazyloaded)
    const res = await Promise.all(
      urlArray.noresUrlArray.map((item, idx) =>
        useFetch<AsuTuitionApiResponse>(item, {
          server: false,
          lazy: true,
          key: `${this.programCode}-${this.careerType}-nores-${idx}`,
        })
      )
    );
    return res;
  };
}
