<template>
  <section class="py-space-md py-md-space-lg py-lg-space-xxl bg-light-2">
    <div class="container px-lg-0">
      <!-- title -->
      <div class="row gx-0">
        <div class="col">
          <h2 class="h2-medium mb-space-md mb-md-space-sm mb-lg-space-lg">
            {{ title }}
          </h2>
        </div>
      </div>
      <!-- card degrees -->
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
        <div
          class="col card-deck mx-0 mb-space-xs mb-lg-space-md"
          v-for="degree in degrees"
        >
          <card-degree-search
            class="mx-0 text-dark-3 program-card h-100"
            border-variant="light-2"
            :image-source="degree.degreeImage!"
            :image-alt="degree.title!"
            :label="category(degree!)"
            :title="degree.title!"
            :show-description="false"
            :footer-label="startLabel(degree.nextStartDate!)"
            footer-cta-text="Learn more"
            :footer-cta-link="degree.detailPage!"
            @cardClick="($event) => handleCardClick(degree!, $event)"
            @ctaClick="
              ($event) => handleLinkEvent(degree!, 'learn more', $event)
            "
            @iconClick="
              ($event) => handleLinkEvent(degree!, 'right chevron', $event)
            "
          ></card-degree-search>
        </div>
      </div>
      <!-- see more button -->
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-5 col-lg-4">
          <NuxtLink
            external
            href="/online-degree-programs/"
            class="btn btn-secondary btn-block fw-bold w-100"
            @click.native="handleAllDegreesClick"
          >
            See all degrees
          </NuxtLink>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { CardDegreeSearch } from "@rds-vue-ui/card-degree-search";
import { DegreeSeekingProgram } from "~/server/resolvers/resolvers-types";

interface Degree {
  id: string;
  title: string;
  nextStartDate: string;
  category: {
    title: string;
  };
  detailPage: string;
  degreeImage: string;
}

interface Props {
  title?: string;
  degrees: DegreeSeekingProgram[];
}

const props = withDefaults(defineProps<Props>(), {
  title: "You might also be interested in",
});

const category = (degree: DegreeSeekingProgram) => {
  return degree.category!.title as string;
};

const startLabel = (startDate: string) => {
  return startDate ? `Starts ${startDate}` : "Start date N/A";
};

const handleCardClick = (degree: DegreeSeekingProgram, event: Event) => {
  event.preventDefault();
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    props.title.toLowerCase(),
    degree.title!.toLowerCase(),
    "interested programs"
  );
  navigateTo(degree.detailPage, {
    open: {
      target: "_self",
    },
  });
};

const handleLinkEvent = (
  degree: DegreeSeekingProgram,
  text: string,
  event: Event
) => {
  event.preventDefault();
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    `${props.title} ^ ${degree.title}`.toLowerCase(),
    text.toLowerCase(),
    "interested programs"
  );

  navigateTo(degree.detailPage, {
    open: {
      target: "_self",
    },
  });
};

const handleAllDegreesClick = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    props.title.toLowerCase(),
    "see all degrees",
    "interested programs"
  );
};
</script>

<style lang="scss" scoped>
.program-card:hover {
  cursor: pointer;
}
</style>
